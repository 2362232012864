<div class="documentation">
    <div class="documentation-title">
        <p>Documentación adjunta</p>
    </div>
    <div class="documentation-main">

        <div *ngIf="mobileSize">
            <div class="documentation-main-orden" (click)="orderDocuments(true)">
                <span>Orden </span>
                <div [ngClass]="orderDesc ? 'order-documentation-up': 'order-documentation-down'"></div>
            </div>
            <div *ngIf="documents.length==0">
                <div class="documentation-main-sup">
                    <div class="left">
                    </div>
                    <div class="center-mobile">
                        <span>Fecha inserción:</span><br>
                    </div>
                    <div class="right-title-mobile">
                        <span>Nombre correspondencia:</span><br>
                    </div>
                    <div class="right-format-mobile">
                        <span>Formato:</span><br>
                    </div>
                </div>
            </div>
            <div *ngIf="documents.length>0">
                <div *ngFor="let document of documents">
                    <div class="documentation-main-sup">
                        <div class="left">
                        </div>
                        <div class="center-mobile">
                            <span>Fecha inserción:</span><br>
                            <a (click)="onGetEventFile(document)">{{document.creationDate | date: 'dd/MM/yyyy'}}</a>
                        </div>
                        <div class="right-title-mobile">
                            <span>Nombre correspondencia:</span><br>
                            <a (click)="onGetEventFile(document)">{{document.name}}</a>
                        </div>
                        <div class="right-format-mobile">
                            <span>Formato:</span><br>
                            <a (click)="onGetEventFile(document)">{{document.format}}</a>
                        </div>
                    </div>
                    <hr size="1rem" style="margin-top: 0.5rem;" *ngIf="documents.length>1" />
                </div>
            </div>
        </div>
        <div *ngIf="desktopSize">
            <div class="documentation-main-sup">
                <div class="left">
                </div>
                <div class="center" (click)="orderDocuments(true)">
                    <span>Fecha inserción &nbsp;</span>
                    <div [ngClass]="orderDesc ? 'order-documentation-up': 'order-documentation-down'"></div>
                </div>
                <div class="right-title">
                    <span>Nombre correspondencia:</span>
                </div>
                <div class="right-format">
                    <span>Formato:</span>
                </div>
            </div>
            <div class="documentation-main-inf " *ngFor="let document of documents">
                <div class="left">
                    <div class="img-inner"></div>
                </div>
                <div class="center">
                    <a (click)="onGetEventFile(document)">{{document.creationDate | date: 'dd/MM/yyyy'}}</a>
                </div>
                <div class="right-title tooltip">
                    <a (click)="onGetEventFile(document)">{{document.name}}</a>
                    <span class="tiptext">{{document.name}}</span>
                </div>
                <div class="right-format">
                    <a (click)="onGetEventFile(document)">{{document.format}}</a>
                </div>
            </div>
        </div>

        <div class="documentation-main-cont">
            <div class="left"></div>
            <div class="right">

                <ngfFormData [files]="files" postName="file" [(FormData)]="uploadFormData"></ngfFormData>

                <div [hidden]="files.length > 0">
                    <div ngfDrop selectable="1" [(validDrag)]="validComboDrag" [(files)]="files"
                        [(dragFiles)]="dragFiles" class="well my-drop-zone"
                        [class.invalid-drag]="validComboDrag === false" [class.valid-drag]="validComboDrag"
                        (filesChange)="lastFileAt = getDate()">
                        <div class="img"></div>
                        <p>Haga clic o arrastre para adjuntar un documento.</p>
                    </div>
                </div>

                <div *ngIf="files.length > 0 && checkFile()" class="files">
                    <div class="files-main" *ngFor="let item of files; let i = index">
                        <div class="name" *ngIf="checkFiles(i)">
                            <div class="img">
                                <div class="img-inner"></div>
                            </div>
                            <p>{{ item.name }}</p>
                        </div>
                        <div class="size">
                            <p>{{ item.size / 1024 / 1024 | number: ".2" }} MB</p>
                        </div>
                        <div class="erase">
                            <button type="button" class="btn btn-danger btn-xs" (click)="files.splice(i, 1)"></button>
                        </div>
                    </div>
                </div>

                <app-spinner-button *ngIf="files.length > 0" [label]="getButtonLabel()" [enabled]="!running"
                    [running]="running" (click)="onUpload()">
                </app-spinner-button>

            </div>


        </div>
    </div>
</div>