export const environment = {

    production: true,

    authorization: 'RlJPTlRFTkRfTUlEQVM6a0lqb2lRMnhwWlc1MFNXUWlMQ0pzWVhOMFEyOXVibVZqZEdsdmJpSTZiblZzYkN3aWMyTnZjR1VpT2xzaWM=',
    clientId: 'FRONTEND_MIDAS',

    urlWordpressClubPropietario:'/club-del-propietario/detalle/29/788',

    backendUrlWithContext: 'https://clientes.onlygal.es/midas_api',
    wordpressUrl: 'https://clienteswp.onlygal.es',
    frontEndUrl: 'https://clientes.onlygal.es',

    chatUrl: 'https://omni.invoxcontact.com',
    chatToken: 'sM29Zt4M9A7kywJCsx8vjgZ1',
    chatPack: '/packs/js/sdk.js',

    blogUrl: 'https://www.onlygal.es/juridico/wp-json/wp/v2/posts?_embed',

    reCaptchaKey: '6LfVqtsZAAAAAHAkP_Y9U3UypHS7XvZVgGaFeEFI',
    siteKey: '6LfVqtsZAAAAAAKf567Li1e-JM1In-pBVXY4U31L',

};
