import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt';

import 'rxjs/add/operator/toPromise';

import { Router } from '@angular/router';
import { AuthenticationDTO } from '../side-by-side-template/model/AuthenticationDTO';
import { environment } from 'environments/environment';
import { CommonsUtils } from 'app/commons/CommonsUtils';
import { UserDTO } from './UserDTO';
import { UserLastConnectionDTO } from './UserLastConnectionDTO';
import { DomainService } from '../services/domain-service';
import { ResetService } from 'app/services/reset-service';
import { ToastrService } from 'ngx-toastr';
import { CommonsText } from '../commons/CommonsText';


@Injectable()
export class AuthService {

    private token: any;
    private user: UserDTO;
    private products: any[];
    private club: boolean;


    constructor(
        private router: Router, private http: Http, private jwtHelper: JwtHelperService, private httpclient: HttpClient,
        private domainService: DomainService, private resetService: ResetService, private toastr: ToastrService) {
        this.loadUserConstructor();
    }

    loadUserConstructor() {
        if (!this.isTokenExpired()) {
            const decoded = this.jwtHelper.decodeToken(this.getToken());
            var innovatoken = JSON.parse(localStorage.getItem('token_innova'));
            try {
                this.user = new UserDTO();
                this.user.name = innovatoken.name;
                this.user.lastName = innovatoken.lastName;
                this.user.documentNumber = decoded.documentNumber;
                this.user.documentType = decoded.documentType;
                this.user.language = decoded.language;
            } catch (e) {
                localStorage.removeItem('token');
                localStorage.removeItem('token_innova');
                localStorage.removeItem('entity');
                //console.error("Error de conectividad : " + e);
                this.toastr.error(CommonsText.VUELVA_INICAR_SESION, CommonsText.SESION_USUARIO_CADUCADA);
                return true;
            }


            if (null != decoded.lastConnection) {
                const v: UserLastConnectionDTO = decoded.lastConnection;
                this.user.lastConnection =
                    this.formatNumber(v.dayOfMonth) +
                    ' / ' + this.formatNumber(v.monthValue) +
                    ' / ' + v.year +
                    '  -  ' + this.formatNumber(v.hour) +
                    ' : ' + this.formatNumber(v.minute);
            }

            this.user.personId = decoded.personId;
            this.user.entities = innovatoken.entities;
            this.user.productsIds = innovatoken.productsIds;

            const replacement = localStorage.getItem('token_replacement');
            if ((null !== replacement) && ('' !== replacement)) {
                this.user.name = replacement;
            }
            this.club = false;
            this.user.productsIds.forEach(element => {
                if (element === 'PP09' || element === 'PP11' || element === 'DJ13') {
                    this.club = true;
                    return;
                }
            });
        }
    }

    /**
     * Comando que se conecta al backend e inicia sesión
     * @param login parametro del usuario
     */
    onAuthenticate(authentication: AuthenticationDTO): Promise<any> {

        localStorage.clear();

        this.resetService.logout(null);

        const url = this.domainService.backendUrlWithContext + '/oauth/token';
        const headers = CommonsUtils.headersBearer();

        const form = new FormData();
        form.append('grant_type', 'password');
        form.append('clientId', environment.clientId);
        form.append('username', authentication.username);
        form.append('password', authentication.password);

        localStorage.removeItem('token_replacement');
        localStorage.removeItem('entity');

        return this.httpclient.post<any>(url, form, { headers: headers }).toPromise().then(response => {
            const jsonResponse = response;

            this.token = jsonResponse.access_token;
            localStorage.setItem('token', jsonResponse.access_token);
            this.loadUser();
        })
    }

    /**
     * Comando que se conecta al backend y comprueba el usuario contra INNOVA
     * @param login parametro del usuario
     */
    innovaLogin(authentication: AuthenticationDTO): Promise<any> {

        const url = this.domainService.backendUrlWithContext + '/users/innovaUser';
        const headers = CommonsUtils.headersBearer();

        const form = new FormData();
        form.append('username', authentication.username);

        localStorage.removeItem('entity');

        return this.httpclient.post<any>(url, form, { headers: headers }).toPromise().then(response => {
            this.loadUserInnova(response);
            return this.user;
        })
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    /**
     * Comando que comprueba si el token ha caducado
     */
    isTokenExpired() {
        try {
            const token = this.getToken();
            this.jwtHelper.isTokenExpired();
            return !token || this.jwtHelper.isTokenExpired(token, 0);
        } catch (e) {
            return true;
        }
    }

    formatNumber(value: Number): string {
        return (value < 10) ? '0' + value : value.toString();
    }
    /**
     * Comando que carga los datos del token al objeto de usuario
     */
    loadUser() {
        if (!this.isTokenExpired()) {
            const decoded = this.jwtHelper.decodeToken(this.getToken());
            this.user = new UserDTO();
            this.user.documentNumber = decoded.documentNumber;
            this.user.documentType = decoded.documentType;
            this.user.language = decoded.language;

            if (null != decoded.lastConnection) {
                const v: UserLastConnectionDTO = decoded.lastConnection;
                this.user.lastConnection =
                    this.formatNumber(v.dayOfMonth) +
                    ' / ' + this.formatNumber(v.monthValue) +
                    ' / ' + v.year +
                    '  -  ' + this.formatNumber(v.hour) +
                    ' : ' + this.formatNumber(v.minute);
            }

            this.user.personId = decoded.personId;

        }
    }

    loadUserInnova(json: any) {

        this.user.name = json.response.name;
        this.user.lastName = json.response.lastName;

        this.user.entities = json.response.entities;
        this.user.productsIds = json.response.productsIds;
        var innovaInfo = { 'name': json.response.name, 'lastName': json.response.lastName, 'entities': json.response.entities, 'productsIds': json.response.productsIds }

        localStorage.setItem('token_innova', JSON.stringify(innovaInfo));

        const replacement = localStorage.getItem('token_replacement');
        if ((null !== replacement) && ('' !== replacement)) {
            this.user.name = replacement;
        }
        this.club = false;
        this.user.productsIds.forEach(element => {
            if (element === 'PP09' || element === 'PP11' || element === 'DJ13') {
                this.club = true;
                return;
            }
        });

    }

    /**
     * Comando que comprueba que el usuario está autorizado.
     */
    isLogged(): Boolean {
        if (this.isTokenExpired()) {
            return false;
        }
        return true;
    }

    /**
     * Comando que se desconecta
     */
    logout() {
        this.token = null;
        this.user = null;
        this.resetService.logout(localStorage.getItem('token'))
        localStorage.removeItem('token_innova');
        localStorage.removeItem('clientData');
        localStorage.removeItem('token');
        localStorage.removeItem('entity');
        localStorage.removeItem('token_replacement');
        localStorage.removeItem('relationType');
        localStorage.removeItem('categorias');
        this.router.navigate(['/authentication']);
    }

    /**
     * Comando que recupera los datos del usuario
     */
    getUser(): UserDTO {
        return this.user;
    }

    setSelectedEntity(key) {
        localStorage.setItem('entity', key);
    }

    getSelectedEntity(): String {
        return localStorage.getItem('entity');
    }

    getRelation(): String {
        return localStorage.getItem('relationType');
    }

    public getIPAddress() {
        return this.http.get("https://api.ipify.org");
    }

    getProductos(): boolean {
        return this.club;
    }


}
