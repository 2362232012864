/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authentication-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/authentication-form/authentication-form.component.ngfactory";
import * as i3 from "../../components/authentication-form/authentication-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../security/auth.service";
import * as i6 from "../../../services/audity-service";
import * as i7 from "../../../shared/services/wordpress/club-propietario.service";
import * as i8 from "@angular/router";
import * as i9 from "ngx-toastr";
import * as i10 from "../../../commons/check-browser-modal/check-browser-modal.component.ngfactory";
import * as i11 from "../../../commons/check-browser-modal/check-browser-modal.component";
import * as i12 from "ngx-device-detector";
import * as i13 from "../../components/welcome-text/welcome-text.component.ngfactory";
import * as i14 from "../../components/welcome-text/welcome-text.component";
import * as i15 from "@angular/common";
import * as i16 from "./authentication-page.component";
import * as i17 from "../../../services/wordpress-service";
var styles_AuthenticationPageComponent = [i0.styles];
var RenderType_AuthenticationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthenticationPageComponent, data: {} });
export { RenderType_AuthenticationPageComponent as RenderType_AuthenticationPageComponent };
function View_AuthenticationPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "section", [["class", "section-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-authentication-form", [], null, [[null, "onAuthenticated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAuthenticated" === en)) {
        var pd_0 = (_co.onAuthenticated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AuthenticationFormComponent_0, i2.RenderType_AuthenticationFormComponent)), i1.ɵdid(2, 114688, null, 0, i3.AuthenticationFormComponent, [i4.FormBuilder, i5.AuthService, i6.AudityService, i7.ClubPropietarioService, i8.Router, i9.ToastrService], null, { onAuthenticated: "onAuthenticated" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_AuthenticationPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["DAS evoluciona a onLygal"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["PARA ACTIVAR TUS SOLUCIONES LEGALES"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["href", "https://onlygal.es/conocenos"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["> CONOCE C\u00D3MO"])), (_l()(), i1.ɵeld(10, 0, null, null, 12, "div", [["class", "sbs-back"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-check-browser-modal", [], null, [["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("document:mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onGlobalClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_CheckBrowserModalComponent_0, i10.RenderType_CheckBrowserModalComponent)), i1.ɵdid(12, 114688, null, 0, i11.CheckBrowserModalComponent, [i12.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 9, "div", [["class", "container sbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-welcome-text", [], null, null, null, i13.View_WelcomeTextComponent_0, i13.RenderType_WelcomeTextComponent)), i1.ɵdid(16, 49152, null, 0, i14.WelcomeTextComponent, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AuthenticationPageComponent_1)), i1.ɵdid(18, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "sub container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 12, 0); var currVal_0 = !_co.authenticated; _ck(_v, 18, 0, currVal_0); }, null); }
export function View_AuthenticationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-authentication-page", [], null, null, null, View_AuthenticationPageComponent_0, RenderType_AuthenticationPageComponent)), i1.ɵdid(1, 114688, null, 0, i16.AuthenticationPageComponent, [i8.Router, i17.WordpressService, i9.ToastrService, i5.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationPageComponentNgFactory = i1.ɵccf("app-authentication-page", i16.AuthenticationPageComponent, View_AuthenticationPageComponent_Host_0, {}, {}, []);
export { AuthenticationPageComponentNgFactory as AuthenticationPageComponentNgFactory };
