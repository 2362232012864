import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-contact-line',
    templateUrl: './contact-line.component.html',
    styleUrls: ['./contact-line.component.scss']
})
export class ContactLineComponent  {

}
