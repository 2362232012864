import { Component, OnInit } from '@angular/core';
import { WordpressService } from 'app/services/wordpress-service';
import { ToastrService } from 'ngx-toastr';
import { IsMobileService } from '../../commons/components/isMobile.service';
import { ClientService } from 'app/services/client-service';
import { AuthService } from 'app/security/auth.service';
import { UserDTO } from 'app/security/UserDTO';
import { environment } from '../../../environments/environment';
import { CommonsText } from 'app/commons/CommonsText';

declare function loadChat(user, environment): any;
declare function mostrarChat(): any;
declare function cargarUsuario(user): UserDTO;
declare function abrirChat(): any;
declare function resetChat(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  integration: any;
  client: any;
  isMobile: Boolean = false;
  showDirectAreas: Boolean = true;

  constructor(private wordpressService: WordpressService,
      private toastr: ToastrService,
      private isMobileService: IsMobileService,
      private authService: AuthService, 
      private clientService: ClientService) {
      this.isMobile = isMobileService.isMobile;
  }

  ngOnInit() {
    this.clientService.getClient()
        .then(success => {
            this.client = success;
            this.loadWidgets();
            loadChat(this.client, environment);
            cargarUsuario(this.client);
            mostrarChat();

        }).catch(error => {
            this.loadWidgets();
        });
        
        this.authService.getSelectedEntity() === 'ONLYGAL' ? this.showDirectAreas = true : this.showDirectAreas = false;
  }

  loadWidgets() {
    if (!this.isMobile) {
        this.wordpressService.loadWidget()
            .then(any => {
                this.filterHeaderAdvertisement(this.wordpressService.getWidget('header-advertisement'));
            }).catch(error => {
                this.toastr.error(CommonsText.FALLO_EN_WORDPRESS, CommonsText.PROBLEMA_DE_CONECTIVIDAD, {
                    timeOut: 5000
                });
            })
    }
  }

  filterHeaderAdvertisement(widgetList) {
    const defaultBanner = widgetList.filter(w => (w.id == null || w.id == 'Id'));

    // Si es prioritario
    if (defaultBanner && defaultBanner.length > 0 && defaultBanner[0].priority == 'true') {
        this.integration = defaultBanner[0];
    } else {
        this.clientService.getBannersInfo()
            .then(response => {
                const r = response.response;
                if (widgetList && widgetList.length > 0) {
                    let fil = null;
                    // Si es su cumple
                    if (r.birthday) {
                        fil = widgetList.filter(w => w.id == 'birthday');
                        if (!fil || fil.length <= 0) {
                            fil = defaultBanner;
                        }
                        this.integration = fil[0];
                    } else {
                        // si hay algún banner para la provincia
                        if (r.provinceCode) {
                            fil = widgetList.filter(w => w.id == r.provinceCode);
                            if (!fil || fil.length <= 0) {
                                fil = defaultBanner;
                            }
                            this.integration = fil[0];
                        } else {
                            fil = defaultBanner;
                            this.integration = fil[0];
                        }

                    }
                }
            })
            .catch(err => {
                let fil = defaultBanner;
                this.integration = fil[0];
                console.error(err);
            })
    }
  }

  getIntegrationBackground(): object {
    if (undefined !== this.integration) {
        if (undefined !== this.integration.image) {
            return {
                'background-image': 'url(' + this.integration.image + ')',
                'background-size': 'cover'
            };
        } else {
            return { 'background-image': 'radial-gradient(circle at 52% 0, #4d87c7, #00558c 64%, #00396f)' };
        }
    } else {
        return {};
    }
  }

  getIntegrationLinearBackground(): object {
    if ((undefined !== this.integration) && (undefined === this.integration.image)) {
        return { 'background-image': 'linear-gradient(to bottom, rgba(24, 86, 164, 0), #00396f)' };
    } else {
        return {};
    }
  }

  getHeaderBackground(): object {
    if (undefined === this.integration) {
        return { 'background': 'radial-gradient(circle, rgba(48, 109, 180, 1) 0%, rgba(7, 65, 126, 1) 63%, rgba(2, 59, 114, 1) 100%)' };
    } else {
        return {};
    }
  }

  getProfileBackground(): object {
    if (undefined !== this.integration) {
        return {
            'background': 'radial-gradient(circle, rgba(48, 109, 180, 1) 0%, rgba(7, 65, 126, 1) 63%, rgba(2, 59, 114, 1) 100%)'
        };
    } else {
        return {};
    }
  }
  getEntityClass(){
    switch (localStorage.getItem('entity')) {
        case 'L0639':
            return 'euroins';
        case 'L1454':
            return 'prima';
        case 'C0616':
            return 'seguro-rga';
        case 'C0012':
            return 'eterna-aseguradora';
        case 'C0037':
            return 'santander';
        case 'C0072':
            return 'generali';
        case 'C0124':
            return 'segurcaixa';
        case 'C0133':
            return 'ocaso';
        case 'C0139':
            return 'patria-hispana';
        case 'C0157':
            return 'helvetia';
        case 'C0161':
            return 'dkv';
        case 'C0572':
            return 'lagun-aro';
        case 'C0786':
            return 'mutua';
        case 'DLA03':
            return 'aegon';
        case 'DLA04':
            return 'ergo';
        case 'DLA06':
            return 'amic';
        case 'DLA07':
            return 'allianz';
        case 'DLA09':
            return 'vidacaixa';
        case 'E0217':
            return 'erv';
        case 'L0614':
            return 'nationale-suisse';
        case 'L0639':
            return 'euroins';
        case 'M0084':
            return 'mmt';
        case 'M0199':
            return 'mutua-propietarios';
        default:
            return '';
    }
  }
}
