/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-line.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./contact-line.component";
var styles_ContactLineComponent = [i0.styles];
var RenderType_ContactLineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactLineComponent, data: {} });
export { RenderType_ContactLineComponent as RenderType_ContactLineComponent };
export function View_ContactLineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "contact"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00BFPodemos ayudarte? "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["href", "https://www.onlygal.es/area-cliente/"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cont\u00E1ctanos aqu\u00ED"]))], null, null); }
export function View_ContactLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-line", [], null, null, null, View_ContactLineComponent_0, RenderType_ContactLineComponent)), i1.ɵdid(1, 49152, null, 0, i2.ContactLineComponent, [], null, null)], null, null); }
var ContactLineComponentNgFactory = i1.ɵccf("app-contact-line", i2.ContactLineComponent, View_ContactLineComponent_Host_0, {}, {}, []);
export { ContactLineComponentNgFactory as ContactLineComponentNgFactory };
